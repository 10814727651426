import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTooltip.figmaUrl.android} codeUrl={checklists.componentTooltip.codeUrl.android} checklists={checklists.componentTooltip.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`A tooltip is a short descriptive message that appears near a view when the user long presses on the view or hovers over it. This tooltip is useful if your app uses icons to represent an action or information to save space in the layout.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://teddys-minio-teddys-dev.vsan-apps.playcourt.id/legion/component/tooltip/android_Tooltip_Component.gif",
            "alt": "Legion tooltip android"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion has 4 variants for tooltip :`}</p>
    <h3>{`1. Tooltip Default/Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.btnTooltipDefault.setOnClickListener {
    LgnTooltipDefault.setup(requireContext())
        anchorView = binding.btnTooltipDefault
        label = getString(R.string.label_tooltip_default)
        position = LgnTooltipPosition.RIGHT
    ).show()
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`2. Tooltip Arrow`}</h3>
    <p>{`Legion has 1 state in this variant.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
binding.btnTooltipArrow.setOnClickListener {
    LgnTooltipArrow.setup(requireContext())
        anchorView = binding.btnTooltipArrow
        label = getString(R.string.label_tooltip_arrow)
        position = LgnTooltipPosition.RIGHT
    ).show()
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`3. Tooltip Default/Base + Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
val drawable = ContextCompat.getDrawable(
    requireContext(), com.telkom.legion.component.R.drawable.ic_info_field
) ?: return

LgnTooltipDefaultIcon.setup(requireContext())
    icon = drawable
    label = getString(R.string.label_tooltip_default_icon)
    position = LgnTooltipPosition.RIGHT
).attach(binding.containerTooltipIcon)

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <h3>{`4. Tooltip Arrow + Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
val drawable = ContextCompat.getDrawable(
    requireContext(), com.telkom.legion.component.R.drawable.ic_info_field
) ?: return

LgnTooltipArrowIcon.setup(requireContext())
    icon = drawable,
    label = getString(R.string.label_tooltip_default_icon),
    position = LgnTooltipPosition.RIGHT
    isAnimated = true
).attach(binding.containerTooltipArrowIcon)

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related parameter(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Context`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`context`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set context object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anchor View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`anchorView`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set anchor view`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set label text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set tooltip position in the screen based on anchor view`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Show Animation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isAnimated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show animation in tooltip arrow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on anchor view`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      